#contact-sub{
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
}

#contact-message{
  text-align: center;
  margin: 5% 0 2vh 0;
  width: 80%;
}

#contact-form{
  margin: 0 0 0 0;
	padding: 2% 7% 5% 7%;
	text-align: left;
}

#contact-form button{
  background:none;
  display: block;
  margin: 0 auto 0 auto;
  border: 1px solid var(--navy);
  border-radius: 0;
  color:var(--navy);
  padding: 2% 0;
  width: 15vw;
}

#contact-form button:hover{
  background-color: var(--blue-bright);
  translate: 0 -3px;
  transition-duration: 0.5s;
}


@media only screen and (max-width: 768px) {
  #contact-form button{
      width: 30vw;
  }
}

input{
	background:none;
	border: none;
  border-bottom: 1px solid  var(--grey);
  font-size: 1.0rem;
  line-height: 25px;
  padding-top: 2%;
  margin: 0 0 3% 0;
  width: 95%;
}

#fname, #email{
  margin: 0 3% 0 0;
  width: 45%;
}

#lname, #subject{
  width: 45%;
}

textarea{
	background:none ;
  border: 1px solid  var(--grey);
  font-family: var(--font-sans);
  font-size: 1.0rem;
	height: 100px;
  margin: 5% 0 5% 0;
	width: 95%;
}



#footer{
  align-items: center;
  background-color: var(--yellow);
  border-bottom-left-radius: 10px ;
  border-bottom-right-radius: 10px ;
  display: flex;
  height: 5vh;
  justify-content: space-around;
  width: 100%;
}

#footer p{
  font-size: 0.7rem;
  margin: 0;
}


.contact-icon{
  display: flex;
  justify-content: flex-start;
  width: 30%;
}

.font-icon{
  color: var(--navy);
  font-size: 1.2rem;
  margin: 0 20% 0 0;
}

.font-icon:hover{
  color: var(--blue-bright);
  cursor: pointer;
}

.contact-icon a{
  margin: 0 20% 0 0;
}
