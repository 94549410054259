#about-content{
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
}

.about-content-p{
  margin: 0 0 10% 0;
}

.highlight{
  color: var(--blue-bright);
  font-weight: 600;
}

.link-gold:hover{
  color: var(--yellow);
}


@media only screen and (max-width: 768px) {
  #about-content{
    padding: 5% 0 0 5%;
  }
}