#experience-sub{
  display: flex;
  flex-direction: column;
  padding: 0 ;
}

#experience-details-section{
  justify-content:flex-end;
  width: 100%;
}

#experience-content{
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 5% 0 3%;
  height: 100%;
}

@media only screen and (max-width: 768px) {
  #experience-content{
    flex-direction: column;
  }
}

#experience-menu{
  min-width: 25%;
}

#experience-menu div{
  padding: 4% 0 4% 3%;
}

#experience-menu div:hover{
  background-color: var(--grey-light);
  cursor: pointer;
}

.experience-chosen{
  border-left: 5px solid var(--yellow);
}

.experience-not-chosen{
  border-left: 5px solid var(--grey);
}

.menu-item-p{
  font-size: 0.9rem;
}


/*  ----------------------------------- */

#experience-details-item{
  width: 70%;
}

#experience-details{
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 40vh;
}

#experience-details h4{
  font-size: 1.25rem;
  margin: 0 0 1% 0;
}

#experience-date{
  color:var(--grey-dark);
  font-size: 0.9rem;
}

#experience-details a{
  color: var(--blue-bright);
}

.responsibility-item{
  margin: 0 0 2% 0;
}

.experience-icon{
  color: var(--blue-bright);
}
