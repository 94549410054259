#navbar-div{
  align-items: center;
  background-color: var(--navy);
  border-bottom: 3px solid var(--blue-bright);
  display: flex;
  height: 10vh;
  justify-content: space-evenly;
  padding: 0 0 0 2%;
  position: sticky;
  top: 0;
  z-index: 100;
}

#navbar-div a{
  color: var(--white);
  margin: 0 5% 0 0;
}

#navbar-div a:hover{
  color: var(--yellow);
}



.progress-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
  height: 3px;
  background: var(--yellow);
  transform-origin: 0%;
}


@media only screen and (max-width: 768px) {
  #navbar-div a{
    margin: 0 2% 0 0;
  }

}