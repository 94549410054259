/* Create root styling */


:root{

  /* Fonts */
  --font-sansSerif: 'Raleway', sans-serif;;

  /* Colours */
  --black: #111111;
  --grey-dark: #3e3e3e;
  --grey: #878787;
  --grey-light:#e1e0e0;
  --grey-extra-light: #f2f2f2;
  --white: #f5f5f5;
  --navy:#050d1a;
  --yellow : #FFC947;
	--blue-bright : #185ADB;
}

/* Elements */

html {
  scroll-behavior: smooth;
}

body {
  background-color: var(--navy);
  font-family: var(--font-sansSerif);
  margin: 0;
}

p{
  font-size: 1.0rem;
  line-height: 2.0;
}

h1, h2, h3, h4, p {
  margin: 0;
}

hr{
  border: none;
  border-radius: 10px;
  border-top: 4px solid var(--yellow);  
  width: 3%;
}

a, u {
  text-decoration: none;
}

/* Classes */


.div-split{
  display: flex;
  flex-direction: row;
}

.div-stack{
  display: flex;
  flex-direction: column;
}

button{
  font-size: 18px;
}

button:hover{
  cursor: pointer;
}

.section-layout{
  align-items: center;
  display: flex;
  flex-direction: row;
  margin: 15vh 0 0 0;
  min-height: 100vh;
  position: relative;
  width: 100%;
}

.title-alignment{
  text-align: center;
  min-width: 40%;
}

.title-alignment h2{
  color: var(--white);
  font-size: 2.0rem;
  text-shadow: 2px 2px 2px black;
}

.section-layout-switch{
  flex-direction: row-reverse;
}


.section-content{
  align-items: center;
  background-color: var(--white);
  border-radius: 10px;
  display: flex;
  min-height: 80vh;
  justify-content: center;
  margin: 10vh 3% 0 3%;
  padding: 0 3% 0 3%;
  width: 60%;
  z-index: 2;
}


@media only screen and (max-width: 768px) {
  .section-layout{
    flex-direction: column;
  }
  .section-content{
    margin: 2vh 5% 0 5%;
    min-height: 70vh;
    width: 80%;
  }
}


/*  ----------------------------------- */

#experience-cta{
  align-items: center;
  background-color: var(--yellow);
  border-radius: 10px;
  display: flex;
  margin: 0 3% 0 3%;
  padding: 1% 5%;
  position: relative;
  z-index: 2;
}

#experience-cta p{
  margin: 0 5% 0 0; 
  width: 80%;
}

#experience-cta button{
  background:none;
  border: 1px solid var(--navy);
  border-radius: 0;
  color:var(--navy);
  padding: 5% 0;
  width: 10vw;
}

#experience-cta button:hover{
  background-color: var(--blue-bright);
  translate: 0 -3px;
  transition-duration: 0.5s;
}