#section-home{
  background-color: var(--navy);
  display: flex;
  height: 110vh;
}

#hero-title{
  align-items: center;
  color: var(--white);
  justify-content: center;
  text-align: center;
  width: 100%;
  z-index: 2;
}

#section-home h1{
  font-family: var(--font-sansSerif);
  font-size: 2.5rem;
  line-height: 2.0;
  text-shadow: 2px 2px 2px black;
}

#hero-name{
  color: var(--yellow);
}

#section-home h3{
  font-family: var(--font-sansSerif);
  font-size: 2.5rem;
  margin: 0;
  padding: 0;
  text-shadow: 2px 2px 2px black;
}


#hero-title a{
  margin: 3% 0 0 0 ;
}

#hero-title button{
  background-color: var(--navy);
  border: 1px solid var(--white);
  border-radius: 0;
  color:var(--white);
  padding: 5% 0;
  width: 20vw;
}


#hero-title button:hover{
  background-color: var(--blue-bright);
  transition-duration: 0.5s;

}

#hero-title button:hover #arrow-icon{
  transform: rotateZ(90deg);
  transition-duration: 0.5s;

}


@media only screen and (max-width: 768px) {
  #hero-title button{
    margin: 10% 0 0 0;
    padding: 7% 0;
    width: 50vw;
  }
  #section-home h3{
  font-size: 2.0rem;
  margin: 5% 0 0 0;
  padding: 0 2% 0 2%;
  }
  #section-home h1{
    font-size: 2.0rem;
    padding: 0 2% 0 2%;
  }
}

