#tech-content{
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

#tech-stack{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 0 5% 0;
  width: 100%;
}

.tech-card{
  align-items: center;
  background-color: var(--grey-light);
  display: flex;
  height: 10%;
  justify-content: center;
  margin: 0 5% 5% 0;
  padding: 1% 3%;
  min-width: 20%;
}

.tech-card p{
  text-align: center;
  width: 100%;
}

#tech-learn h4{
  color: var(--blue-bright);
  margin: 5% 0 0 0;
  text-align: center;
}